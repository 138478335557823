import { functions } from '@/mixins/functions';

export default {
	state: {
		items: [],
	},
	getters: {
		shoppingCartItems: state => state.items,
		totalShoppingCartItems (state) {
			// reduce into a number which contains the sum of each item's amount
			return state.items.reduce((total, item) => {
				return total += (item.amount != undefined && parseInt(item.amount) > 0) ? parseInt(item.amount) : 0;
			}, 0);
		},
		totalShoppingCartAmount (state) {
			// reduce into a number which contains the sum of each item's selling_amount
			return state.items.reduce((total, item) => {
				return total += (item.amount != undefined && parseInt(item.amount) > 0 && item.selling_price != undefined && parseInt(item.selling_price) > 0) ? parseFloat(item.amount * item.selling_price) : 0;
			}, 0);
		},
	},
	mutations: {
		setItems (state, payload) {
			state.items = payload;
		},
		addItem (state, payload) {
			state.items = state.items.push(payload);
		},
		removeItem (state, payload) {
			state.items = state.items.filter(item => item.id != payload.id);
		},
		clearItems (state) {
			state.items = [];
		}
	},
	actions: {
		getShoppingCartItems ({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				functions.methods.req('GET', '/shopping_cart_item/getAll?q={"select":"v__shopping_cart_items__","limit":100}').then((response) => {
					if (response.status) {
						commit('setItems', response.data);
					}
					resolve();
				}, (error) => {
					reject(error);
				});
			});
		},
		addShoppingCartItem ({ commit, dispatch }, { payload, _this }) {
			return new Promise((resolve, reject) => {
				functions.methods.req('POST', '/shopping_cart_item/insert', payload).then((response) => {
					if (response.status) {
						commit('addItem', response.data);
						dispatch('getShoppingCartItems');

						_this.$notify({
							title: _this.$t('event_option.insert-shopping-cart-item.success'),
							text: _this.$t('event_option.insert-shopping-cart-item-description.success'),
							type: 'success',
						});
						_this.closeModal('addToCartModel');
						
						resolve();
					} else {
						_this.$swal(_this.$t('event_option.insert-shopping-cart-item.failed'), _this.$t(response.message), 'warning');
						reject(response.message);
					}
				}, (error) => {
					reject(error);
				});
			});
		},
		removeShoppingCartItem ({ commit, dispatch }, { payload, _this }) {
			return new Promise((resolve, reject) => {
				_this.$swal({
					title: _this.$t('shoppingcart.delete.item.confirm'),
					text: _this.$t('shoppingcart.delete.item.confirm.description'),
					icon: 'warning',
					showDenyButton: true,
					showCancelButton: false,
					confirmButtonText: _this.$t('shoppingcart.delete.label'),
					denyButtonText: _this.$t('cancel.label'),
					reverseButtons: true,
					focusConfirm: false,
					customClass: {
						confirmButton: 'btn swal-btn-danger',
						denyButton: 'btn swal-btn-light'
					},
				}).then((result) => {
					if (result.isConfirmed) {
						functions.methods.req('GET', '/shopping_cart_item/delete/' + payload.id).then((response) => {
							if (response.status) {
								commit('removeItem', payload);
								dispatch('getShoppingCartItems');
							} else {
								reject(response.message);
							}
						});
					}
					resolve();
				});
			});
		},
		updateShoppingCartItem ({ dispatch }, { payload, _this }) {
			return new Promise((resolve, reject) => {
				if (payload.id !== undefined && payload.id !== null) {
					functions.methods.req('POST', '/shopping_cart_item/update/' + payload.id, payload).then((response) => {
						if (response.status) {
							dispatch('getShoppingCartItems');
							resolve();
						} else {
							_this.$swal(_this.$t('shoppingcart.update.cart.failed'), _this.$t('shoppingcart.update.cart.failed.description'), 'error');
							reject(response.message);
						}
					}, (error) => {
						reject(error);
					});
				} else {
					reject('shoppingcart.update.cart.failed');
				}
			});
		},
		clearShoppingCartItems ({ commit }) {
			return new Promise((resolve) => {
				commit('clearItems');
				resolve();
			});
		},
	}
}